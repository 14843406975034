
import Vue from 'vue';


export default Vue.extend({
    name: 'HomePage',
    props: {
        msg: String,
    },
    data() {
        return {
            a: 10, b: 20
        }
    },
    mounted() {
        // console.log(this.a)
    },

});
