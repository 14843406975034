
import Vue from 'vue';
// import eruda from 'eruda';
// import { debug } from '@/config';

export default Vue.extend({
  name: 'App',
  created() {
    // console.log('*************app created', this.$route)
    // console.log('********:process.env.BASE_URL::', process.env.BASE_URL);
  }
});

// if (debug) eruda.init();
