import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../pages/home/HomePage.vue';   //home page 测试

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../pages/home/testPage.vue'),
        meta: { title: '测试' }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../pages/about/AboutPage.vue'),
        meta: { title: '关于我们' },
    },
    /* 我的点点币 coin-redeem */
    {
        path: '/coin-redeem',
        name: 'coinRedeem',
        component: () => import('../pages/coinRedeem/CoinRedeem.vue'),
        meta: { title: '我的点点币' }
    },
    /* 开通记录 purchase-record */
    {
        path: '/purchase-record',
        name: 'purchaseRecord',
        component: () => import('../pages/purchaseRecord/PurchaseRecordPage.vue'),
        meta: { title: '开通记录' }
    },

    /* 留言反馈 feedback */
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import('../pages/feedback/FeedbackPage.vue'),
        meta: { title: '意见反馈' }
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('../pages/help/HelpPage.vue'),
        meta: { title: '帮助与反馈' }
    },
    {
        path: '/help/content',
        name: 'helpContent',
        component: () => import('../pages/help/HelpContentPage.vue'),
        meta: { title: '帮助与反馈' }
    },
    {
        path: '/help/wide',
        name: 'helpWide',
        component: () => import('../pages/help/HelpWidePage.vue'),
        meta: { title: '帮助与反馈' }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

//全局后置钩子
router.afterEach((to, from) => {
    // console.log(to.fullPath)
    document.title = to.meta?.title ? to.meta.title as string : '儿歌点点';
})

export default router